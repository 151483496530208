import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    Button,
    List,
    ListItem,
    Card,
    CardContent,
    CircularProgress,
    Box,
    Divider,
    IconButton
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const RQuestionPage = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const header = query.get('header');

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`https://mynewhomeschool.com/student/admin/api/review_questions.php?header=${encodeURIComponent(header)}`);
                if (!response.ok) throw new Error('Network response was not ok');
                
                const data = await response.json();
                const questionsWithAnswers = await Promise.all(data.map(async (question) => {
                    const answersResponse = await fetch(`https://mynewhomeschool.com/student/admin/api/fetch_answers.php?question_id=${question.id}`);
                    const answers = await answersResponse.json();
                    return { ...question, answers };
                }));
                
                setQuestions(questionsWithAnswers);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching questions:", error);
                setLoading(false);
            }
        };
        fetchQuestions();
    }, [header]);

    const handleReviewAll = async () => {
        try {
            const query = new URLSearchParams(location.search); // Retrieve the header from useLocation
            const header = query.get('header'); // Get the header value
            
            const response = await fetch('https://mynewhomeschool.com/student/admin/api/review_questions.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ header }),  // Send the header in the body
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setQuestions([]); // Clear questions from state after approval
            alert("All questions have been approved for this header!");
        } catch (error) {
            console.error("Error approving questions:", error);
        }
    };

    return (
        <Grid container spacing={3} style={{ padding: '20px', maxWidth: '90%', margin: '0 auto' }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>Questions for "{header}"</Typography>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleReviewAll} fullWidth>
                    Approve All
                </Button>
            </Grid>

            <Grid item xs={12}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    questions.length > 0 ? (
                        <List>
                            {questions.map((question, index) => (
                                <ListItem key={question.id} disableGutters>
                                    <Card variant="outlined" style={{ width: '100%' }}>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {`${index + 1}. ${question.question_text}`}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" paragraph>
                                                {question.additional_info || 'No additional info provided'}
                                            </Typography>
                                            {question.question_image && (
                                                <img 
                                                    src={`https://mynewhomeschool.com/student/admin/${question.question_image}`} 
                                                    alt="Question" 
                                                    style={{ maxWidth: '100%', marginBottom: '10px' }}
                                                />
                                            )}
                                            <Divider />
                                            <Box mt={2}>
                                                <Typography variant="subtitle1" gutterBottom>Answers:</Typography>
                                                {question.answers && question.answers.map((answer, answerIndex) => (
                                                    <Box key={answer.id} mb={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Box>
                                                            <Typography variant="body2" style={{ fontWeight: answer.is_correct==1 ? 'bold' : 'normal', color: answer.is_correct ==1 ? 'green' : 'red' }}>
                                                                {answer.answer_text} {answer.is_correct == 1 ? '(Correct Answer)' : '(Incorrect Answer)'}
                                                            </Typography>
                                                            {answer.answer_image && (
                                                                <img 
                                                                    src={`https://mynewhomeschool.com/student/admin/${answer.answer_image}`} 
                                                                    alt="Answer" 
                                                                    style={{ maxWidth: '100%', marginTop: '5px' }}
                                                                />
                                                            )}
                                                        </Box>
                                                        {/* <Box>
                                                            <IconButton color="primary">
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton color="secondary">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box> */}
                                                    </Box>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1">No questions available for this header.</Typography>
                    )
                )}
            </Grid>
        </Grid>
    );
};

export default RQuestionPage;
