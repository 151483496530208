import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, IconButton, Pagination, Paper, InputBase, Button,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, List, ListItem, ListItemText, Backdrop, CircularProgress
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const EssayQuestionsPage = () => {
  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [levels, setLevels] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);

  // New state variables for headers
  const [headers, setHeaders] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState(null);

  // Initialize selectedLevel and selectedUnit from localStorage
  const [selectedLevel, setSelectedLevel] = useState(() => {
    const storedLevel = localStorage.getItem('selectedLevel');
    return storedLevel ? JSON.parse(storedLevel) : null;
  });
  const [selectedUnit, setSelectedUnit] = useState(() => {
    const storedUnit = localStorage.getItem('selectedUnit');
    return storedUnit ? JSON.parse(storedUnit) : null;
  });

  const itemsPerPage = 5;
  const navigate = useNavigate();

  // Fetch levels on component mount
  useEffect(() => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=fetch_levels`)
      .then((res) => res.json())
      .then((data) => {
        const fetchedLevels = data.levels || [];
        setLevels(fetchedLevels);

        // Validate selectedLevel from localStorage
        if (selectedLevel) {
          const matchedLevel = fetchedLevels.find(level => level.id === selectedLevel.id);
          if (!matchedLevel) {
            setSelectedLevel(null);
            localStorage.removeItem('selectedLevel');
          }
        }
      })
      .catch((error) => console.error("Error fetching levels:", error))
      .finally(() => setLoading(false));
  }, []);

  // Fetch units when a level is selected
  useEffect(() => {
    if (selectedLevel !== null) {
      setLoading(true);
      fetch(`https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=fetch_units&level_id=${selectedLevel.id}`)
        .then((res) => res.json())
        .then((data) => {
          const fetchedUnits = data.units || [];
          setUnits(fetchedUnits);

          // Validate selectedUnit from localStorage
          if (selectedUnit) {
            const matchedUnit = fetchedUnits.find(unit => unit.id === selectedUnit.id);
            if (!matchedUnit) {
              setSelectedUnit(null);
              localStorage.removeItem('selectedUnit');
            }
          }
        })
        .catch((error) => console.error("Error fetching units:", error))
        .finally(() => setLoading(false));
    }
  }, [selectedLevel]);

  // Fetch essay questions when level and unit are selected
  useEffect(() => {
    if (selectedLevel !== null && selectedUnit !== null) {
      fetchQuestions();
    }
  }, [selectedLevel, selectedUnit]);

  const fetchQuestions = () => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=fetch&level_id=${selectedLevel.id}&unit_id=${selectedUnit.id}`)
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.questions || []);
      })
      .catch((error) => console.error("Error fetching essay questions:", error))
      .finally(() => setLoading(false));
  };

  // Filter questions based on search term
  useEffect(() => {
    const filtered = questions.filter(q =>
      q.question_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.question_header?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  // Extract headers from questions
  useEffect(() => {
    const uniqueHeaders = [...new Set(questions.map(q => q.question_header))];
    setHeaders(uniqueHeaders);
  }, [questions]);

  // Store selectedLevel in localStorage whenever it changes
  useEffect(() => {
    if (selectedLevel !== null) {
      localStorage.setItem('selectedLevel', JSON.stringify(selectedLevel));
    } else {
      localStorage.removeItem('selectedLevel');
    }
  }, [selectedLevel]);

  // Store selectedUnit in localStorage whenever it changes
  useEffect(() => {
    if (selectedUnit !== null) {
      localStorage.setItem('selectedUnit', JSON.stringify(selectedUnit));
    } else {
      localStorage.removeItem('selectedUnit');
    }
  }, [selectedUnit]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goBack = () => {
    if (selectedHeader !== null) {
      setSelectedHeader(null);
    } else if (selectedUnit !== null) {
      setSelectedUnit(null);
    } else if (selectedLevel !== null) {
      setSelectedLevel(null);
    } else {
      navigate(-1);
    }
  };

  // Level and Unit selection handlers
  const handleLevelClick = (level) => {
    setSelectedLevel(level);
    setUnits([]); // Reset units when a new level is selected
    setSelectedUnit(null); // Reset selected unit
    setQuestions([]); // Reset questions
    setSelectedHeader(null);
  };

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit);
    setQuestions([]); // Reset questions when a new unit is selected
    setSelectedHeader(null);
  };

  // Question Handlers
  const handleAddQuestion = () => {
    setQuestionToEdit(null);
    setQuestionModalOpen(true);
  };

  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setQuestionModalOpen(true);
  };

  const handleDeleteQuestion = (questionId) => {
    const question = questions.find(q => q.id === questionId);
    setQuestionToDelete(question);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteQuestion = async () => {
    setLoading(true);
    if (questionToDelete) {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: questionToDelete.id }),
      });
      if (response.ok) {
        // Refresh the data
        fetchQuestions();
        setDeleteConfirmOpen(false);
        setQuestionToDelete(null);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleQuestionSubmit = (formData) => {
    // Close the modal before starting loading
    setQuestionModalOpen(false);
    setLoading(true);
    const form = new FormData();
    form.append('question_header', formData.question_header);
    form.append('question_text', formData.question_text);
    form.append('answer_text', formData.answer_text);
    form.append('additional_info', formData.additional_info);
    form.append('type', 'essay_question');
    form.append('level_id', selectedLevel.id);
    form.append('unit_id', selectedUnit.id);

    if (formData.question_image) {
      form.append('question_image', formData.question_image);
    }
    if (formData.answer_image) {
      form.append('answer_image', formData.answer_image);
    }
    if (formData.additional_info_image) {
      form.append('additional_info_image', formData.additional_info_image);
    }
    if (questionToEdit) {
      form.append('id', questionToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
      .then(() => {
        // Refresh the data
        fetchQuestions();
        setQuestionToEdit(null);
      })
      .catch((error) => {
        console.error("Error updating question:", error);
        setLoading(false);
      });
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
      .then(() => {
        // Refresh the data
        fetchQuestions();
      })
      .catch((error) => {
        console.error("Error adding question:", error);
        setLoading(false);
      });
    }
  };

  // Question Modal Component
  const QuestionModal = ({ open, onClose, onSubmit, question, selectedHeader }) => {
    const [formData, setFormData] = useState({
      question_header: question?.question_header || selectedHeader || '',
      question_text: question?.question_text || '',
      question_image: null,
      answer_text: question?.answer_text || '',
      answer_image: null,
      additional_info: question?.additional_info || '',
      additional_info_image: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    useEffect(() => {
      if (question) {
        setFormData({
          question_header: question.question_header || '',
          question_text: question.question_text || '',
          question_image: null,
          answer_text: question.answer_text || '',
          answer_image: null,
          additional_info: question.additional_info || '',
          additional_info_image: null,
        });
      } else {
        setFormData({
          question_header: selectedHeader || '',
          question_text: '',
          question_image: null,
          answer_text: '',
          answer_image: null,
          additional_info: '',
          additional_info_image: null,
        });
      }
    }, [question, selectedHeader]);

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{question ? 'Edit Essay Question' : 'Add Essay Question'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Question Header"
            name="question_header"
            value={formData.question_header}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Question Text"
            name="question_text"
            value={formData.question_text}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
          />
          <input
            type="file"
            name="question_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.question_image && (
            <img src={URL.createObjectURL(formData.question_image)} alt="Question" style={{ width: '150px', marginTop: '10px' }} />
          )}
          <TextField
            margin="dense"
            label="Answer Text"
            name="answer_text"
            value={formData.answer_text}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
          />
          <input
            type="file"
            name="answer_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.answer_image && (
            <img src={URL.createObjectURL(formData.answer_image)} alt="Answer" style={{ width: '150px', marginTop: '10px' }} />
          )}
          <TextField
            margin="dense"
            label="Additional Info"
            name="additional_info"
            value={formData.additional_info}
            onChange={handleChange}
            multiline
            rows={2}
            fullWidth
          />
          <input
            type="file"
            name="additional_info_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.additional_info_image && (
            <img src={URL.createObjectURL(formData.additional_info_image)} alt="Additional Info" style={{ width: '150px', marginTop: '10px' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Question Card Component
  const QuestionCard = ({ question }) => (
    <Card sx={{ mb: 2, p: 2 }}>
      <CardContent>
        <Typography variant="h6">{question.question_header || 'No header provided'}</Typography>
        <Typography variant="body1">{question.question_text || 'No question text provided'}</Typography>
        {question.question_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.question_image}`}
            alt="Question"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
        <Typography variant="body1"><strong>Answer:</strong> {question.answer_text || 'No answer text provided'}</Typography>
        {question.answer_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.answer_image}`}
            alt="Answer"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
        <Typography variant="body1"><strong>Additional Info:</strong> {question.additional_info || 'No additional info provided'}</Typography>
        {question.additional_info_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.additional_info_image}`}
            alt="Additional Info"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <IconButton color="primary" onClick={() => handleEditQuestion(question)}>
          <EditIcon />
        </IconButton>
        {/* <IconButton color="secondary" onClick={() => handleDeleteQuestion(question.id)}>
          <DeleteIcon />
        </IconButton> */}
      </Box>
    </Card>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Backdrop for Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Sidebar */}
      <Box sx={{ width: '250px', borderRight: '1px solid #ccc', p: 2 }}>
        <Button startIcon={<ArrowBackIcon />} onClick={goBack} color="secondary">Back</Button>
        {selectedLevel === null ? (
          // Display levels
          <>
            <Typography variant="h6">Select Level</Typography>
            <List>
              {levels.map((level) => (
                <ListItem button key={level.id} onClick={() => handleLevelClick(level)}>
                  <ListItemText primary={level.title} />
                </ListItem>
              ))}
            </List>
          </>
        ) : selectedUnit === null ? (
          // Display units
          <>
            <Typography variant="h6">Select Subject</Typography>
            <List>
              {units.map((unit) => (
                <ListItem button key={unit.id} onClick={() => handleUnitClick(unit)}>
                  <ListItemText primary={unit.title} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          // Display selected level, unit, and header
          <>
            <Typography variant="h6">Level: {selectedLevel.title}</Typography>
            <Typography variant="h6">Subject: {selectedUnit.title}</Typography>
            {selectedHeader && <Typography variant="h6">Header: {selectedHeader}</Typography>}
          </>
        )}
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 2 }}>
        {selectedLevel !== null && selectedUnit !== null ? (
          selectedHeader === null ? (
            // Display headers
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>Select Question Header</Typography>
              <List>
                {headers.map((header) => (
                  <ListItem button key={header} onClick={() => setSelectedHeader(header)}>
                    <ListItemText primary={header} />
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            // Display questions under the selected header
            <>
              <Button startIcon={<ArrowBackIcon />} onClick={() => setSelectedHeader(null)} color="secondary">Back to Headers</Button>
              <Typography variant="h6" sx={{ mb: 2 }}>Questions under "{selectedHeader}"</Typography>

              <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search essay questions"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Paper>

              <Button onClick={handleAddQuestion} variant="contained" color="primary" startIcon={<AddIcon />}>
                Add Essay Question
              </Button>

              {filteredQuestions.filter(q => q.question_header === selectedHeader).length > 0 ? (
                filteredQuestions
                  .filter(q => q.question_header === selectedHeader)
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((question) => (
                    <QuestionCard
                      key={question.id}
                      question={question}
                    />
                  ))
              ) : (
                <Typography>No essay questions available under this header.</Typography>
              )}

              <Pagination
                count={Math.ceil(filteredQuestions.filter(q => q.question_header === selectedHeader).length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                sx={{ mt: 2 }}
              />

              {/* Question Modal */}
              <QuestionModal
                open={questionModalOpen}
                onClose={() => setQuestionModalOpen(false)}
                onSubmit={handleQuestionSubmit}
                question={questionToEdit}
                selectedHeader={selectedHeader}
              />

              {/* Delete Confirmation Dialog */}
              <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
              >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                  <Typography>Are you sure you want to delete this question?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteConfirmOpen(false)} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={confirmDeleteQuestion} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        ) : (
          <Typography variant="h6" sx={{ mt: 2 }}>Please select a level and subject to view essay questions.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default EssayQuestionsPage;
