import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from 'react-router-dom';

const ReviewQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch('https://mynewhomeschool.com/student/admin/api/review_questions.php');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setQuestions(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching questions:", error);
                setLoading(false);
            }
        };
        fetchQuestions();
    }, []);

    const handleHeaderClick = (header) => {
        navigate(`/admin/rquestionpage?header=${encodeURIComponent(header)}`);
    };

    const uniqueHeaders = [...new Set(questions.map(q => q.question_header))];

    return (
        <Grid container spacing={2} style={{ padding: '20px' }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>Review Questions</Typography>
            </Grid>
            
            <Grid item xs={12}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : uniqueHeaders.length > 0 ? (
                    uniqueHeaders.map(header => (
                        <Accordion key={header}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => handleHeaderClick(header)}>
                                <Typography variant="h6">{header}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => handleHeaderClick(header)}
                                >
                                    Review Questions for {header}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Typography variant="body1">No questions to review.</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default ReviewQuestions;
