import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, IconButton, Pagination, Paper, InputBase, Button,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Checkbox, FormControlLabel
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

const QuestionsPage = () => {
  const { header } = useParams();
  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [answerToEdit, setAnswerToEdit] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const itemsPerPage = 5;
  const navigate = useNavigate();

  // Fetch questions by header
  useEffect(() => {
    fetch(`https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=fetch&header=${header}`)
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.questions || []);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  }, [header]);

  // Filter questions based on search term
  useEffect(() => {
    const filtered = questions.filter(q =>
      q.question_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.question_header?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goBack = () => {
    navigate(-1);
  };

  // Question Handlers
  const handleAddQuestion = () => {
    setQuestionToEdit(null);
    setQuestionModalOpen(true);
  };

  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setQuestionModalOpen(true);
  };

  const handleDeleteQuestion = async (questionId) => {
    const response = await fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: questionId, type: 'question' }),
    });
    if (response.ok) {
      setQuestions(questions.filter(q => q.id !== questionId));
    }
  };

  const handleQuestionSubmit = (formData) => {
    const form = new FormData();
    form.append('question_header', formData.question_header);
    form.append('question_text', formData.question_text);
    form.append('additional_info', formData.additional_info);
    form.append('type', 'question');
    form.append('header', header); // Associate question with header

    if (formData.question_image) {
      form.append('question_image', formData.question_image);
    }
    if (formData.additional_info_image) {
      form.append('additional_info_image', formData.additional_info_image);
    }
    if (questionToEdit) {
      form.append('id', questionToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
      .then(() => {
        // Update the question in the state
        setQuestions(prevQuestions => prevQuestions.map(q => q.id === questionToEdit.id ? { ...q, ...formData } : q));
      });
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
      .then(res => res.json())
      .then(newQuestion => {
        setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
      });
    }
    setQuestionModalOpen(false);
  };

  // Answer Handlers
  const handleAddAnswer = (questionId) => {
    setCurrentQuestionId(questionId);
    setAnswerToEdit(null);
    setAnswerModalOpen(true);
  };

  const handleEditAnswer = (questionId, answer) => {
    setCurrentQuestionId(questionId);
    setAnswerToEdit(answer);
    setAnswerModalOpen(true);
  };

  const handleDeleteAnswer = async (questionId, answerId) => {
    const response = await fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: answerId, type: 'answer' }),
    });
    if (response.ok) {
      setQuestions(prevQuestions => prevQuestions.map(q => {
        if (q.id === questionId) {
          return {
            ...q,
            answers: q.answers.filter(a => a.id !== answerId),
          };
        }
        return q;
      }));
    }
  };

  const handleAnswerSubmit = (formData) => {
    const form = new FormData();
    form.append('answer_text', formData.answer_text);
    form.append('is_correct', formData.is_correct ? 1 : 0);
    form.append('type', 'answer');
    form.append('question_id', currentQuestionId);
    if (formData.answer_image) {
      form.append('answer_image', formData.answer_image);
    }
    if (answerToEdit) {
      form.append('id', answerToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
      .then(() => {
        // Update the answer in the state
        setQuestions(prevQuestions => prevQuestions.map(q => {
          if (q.id === currentQuestionId) {
            return {
              ...q,
              answers: q.answers.map(a => a.id === answerToEdit.id ? { ...a, ...formData } : a),
            };
          }
          return q;
        }));
      });
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
      .then(res => res.json())
      .then(newAnswer => {
        setQuestions(prevQuestions => prevQuestions.map(q => {
          if (q.id === currentQuestionId) {
            return {
              ...q,
              answers: [...q.answers, newAnswer],
            };
          }
          return q;
        }));
      });
    }
    setAnswerModalOpen(false);
  };

  // Question Modal Component
  const QuestionModal = ({ open, onClose, onSubmit, question, header }) => {
    const [formData, setFormData] = useState({
      question_header: question?.question_header || header || '',
      question_text: question?.question_text || '',
      question_image: null,
      additional_info: question?.additional_info || '',
      additional_info_image: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    useEffect(() => {
      if (question) {
        setFormData({
          question_header: question.question_header || '',
          question_text: question.question_text || '',
          question_image: null,
          additional_info: question.additional_info || '',
          additional_info_image: null,
        });
      } else {
        setFormData(prev => ({
          ...prev,
          question_header: header || '',
        }));
      }
    }, [question, header]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{question ? 'Edit Question' : 'Add Question'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Question Header"
            name="question_header"
            value={formData.question_header}
            onChange={handleChange}
            fullWidth
            InputProps={{ readOnly: !question }} // Make read-only when adding a new question
          />
          <TextField
            margin="dense"
            label="Question Text"
            name="question_text"
            value={formData.question_text}
            onChange={handleChange}
            fullWidth
          />
          <input
            type="file"
            name="question_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.question_image && (
            <img src={URL.createObjectURL(formData.question_image)} alt="Question" style={{ width: '150px', marginTop: '10px' }} />
          )}
          <TextField
            margin="dense"
            label="Additional Info"
            name="additional_info"
            value={formData.additional_info}
            onChange={handleChange}
            fullWidth
          />
          <input
            type="file"
            name="additional_info_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.additional_info_image && (
            <img src={URL.createObjectURL(formData.additional_info_image)} alt="Additional Info" style={{ width: '150px', marginTop: '10px' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Answer Modal Component
  const AnswerModal = ({ open, onClose, onSubmit, answer }) => {
    const [formData, setFormData] = useState({
      answer_text: answer?.answer_text || '',
      answer_image: null,
      is_correct: answer?.is_correct == 1 ? true : false,
    });

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    useEffect(() => {
      if (answer) {
        setFormData({
          answer_text: answer.answer_text || '',
          answer_image: null,
          is_correct: answer.is_correct == 1 ? true : false,
        });
      } else {
        setFormData({
          answer_text: '',
          answer_image: null,
          is_correct: false,
        });
      }
    }, [answer]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{answer ? 'Edit Answer' : 'Add Answer'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Answer Text"
            name="answer_text"
            value={formData.answer_text}
            onChange={handleChange}
            fullWidth
          />
          <input
            type="file"
            name="answer_image"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginTop: '15px' }}
          />
          {formData.answer_image && (
            <img src={URL.createObjectURL(formData.answer_image)} alt="Answer" style={{ width: '150px', marginTop: '10px' }} />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.is_correct}
                onChange={handleChange}
                name="is_correct"
                color="primary"
              />
            }
            label="Is Correct"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{answer ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Question Card Component
  const QuestionCard = ({ question }) => (
    <Card sx={{ mb: 2, p: 2 }}>
      <CardContent>
        <Typography variant="h6">{question.question_header || 'No header provided'}</Typography>
        <Typography variant="body1">{question.question_text || 'No question text provided'}</Typography>
        {question.question_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.question_image}`}
            alt="Question"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
        <Typography variant="body1">{question.additional_info || 'No additional info provided'}</Typography>
        {question.additional_info_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.additional_info_image}`}
            alt="Additional Info"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
      </CardContent>

      <Box>
        <Typography variant="h6" sx={{ mt: 2 }}>Answers:</Typography>
        {question.answers && question.answers.length > 0 ? (
          question.answers.map((answer) => (
            <Card key={answer.id} sx={{ display: 'flex', mb: 1, p: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Typography variant="body1">{answer.answer_text || 'No answer text provided'}</Typography>
                {answer.answer_image && (
                  <img
                    src={`https://mynewhomeschool.com/student/admin/${answer.answer_image}`}
                    alt="Answer"
                    style={{ width: '100px', marginTop: '10px' }}
                  />
                )}
                <Typography variant="body2">
                  {Number(answer.is_correct) === 1 ? "Correct Answer" : "Incorrect Answer"}
                </Typography>
              </Box>
              <Box>
                <IconButton color="primary" onClick={() => handleEditAnswer(question.id, answer)}>
                  <EditIcon />
                </IconButton>
                {/* <IconButton color="secondary" onClick={() => handleDeleteAnswer(question.id, answer.id)}>
                  <DeleteIcon />
                </IconButton> */}
              </Box>
            </Card>
          ))
        ) : (
          <Typography>No answers available</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <IconButton color="primary" onClick={() => handleEditQuestion(question)}>
          <EditIcon />
        </IconButton>
        {/* <IconButton color="secondary" onClick={() => handleDeleteQuestion(question.id)}>
          <DeleteIcon />
        </IconButton> */}
        <Button onClick={() => handleAddAnswer(question.id)} variant="contained" color="primary" startIcon={<AddIcon />}>
          Add Answer
        </Button>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Button onClick={goBack} color="secondary">Back to Headers</Button>
      <Typography variant="h6" sx={{ mb: 2 }}>Questions for Header: {header}</Typography>
      
      <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search questions"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Paper>

      <Button onClick={handleAddQuestion} variant="contained" color="primary" startIcon={<AddIcon />}>
        Add Question
      </Button>

      {filteredQuestions.length > 0 ? (
        filteredQuestions.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((question) => (
          <QuestionCard
            key={question.id}
            question={question}
          />
        ))
      ) : (
        <Typography>No questions available</Typography>
      )}

      <Pagination
        count={Math.ceil(filteredQuestions.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      {/* Modals */}
      <QuestionModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        onSubmit={handleQuestionSubmit}
        question={questionToEdit}
        header={header}
      />

      <AnswerModal
        open={answerModalOpen}
        onClose={() => setAnswerModalOpen(false)}
        onSubmit={handleAnswerSubmit}
        answer={answerToEdit}
      />

    </Box>
  );
};

export default QuestionsPage;
