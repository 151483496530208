import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, List, ListItem, ListItemText, Button, Card, CardContent, CardMedia,
  IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Divider, Pagination,
  Backdrop, CircularProgress
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';

// Book Card Component
const BookCard = ({ book, onEdit, onDelete }) => (
  <Card sx={{ display: 'flex', mb: 2 }}>
    <CardMedia
      component="img"
      sx={{ width: 150 }}
      image={`https://mynewhomeschool.com/student/admin/${book.thumbnail_url}`}
      alt={book.title}
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <CardContent>
        <Typography variant="h5">{book.title}</Typography>
        <Typography variant="subtitle1">{book.media_type}</Typography>
        <Typography variant="body2">{book.des}</Typography>
      </CardContent>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 2 }}>
      <IconButton onClick={() => onEdit(book)} color="primary">
        <EditIcon />
      </IconButton>
      {/* <IconButton onClick={() => onDelete(book.id)} color="secondary">
        <DeleteIcon />
      </IconButton> */}
    </Box>
  </Card>
);

// Add/Edit Book Modal Component
const BookModal = ({ open, onClose, onSubmit, book }) => {
  const [formData, setFormData] = useState({
    title: book?.title || '',
    media_type: book?.media_type || '',
    media_url: '',
    thumbnail_url: '',
    des: book?.des || ''
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({
        ...formData,
        [name]: URL.createObjectURL(files[0]),
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  useEffect(() => {
    setFormData({
      title: book?.title || '',
      media_type: book?.media_type || '',
      media_url: '',
      thumbnail_url: '',
      des: book?.des || ''
    });
  }, [book]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{book ? 'Edit Content' : 'Add Content'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
        />

        {/* Media Type Dropdown */}
        <TextField
          select
          margin="dense"
          label="Media Type"
          name="media_type"
          value={formData.media_type}
          onChange={handleChange}
          fullWidth
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Select Media Type</option>
          <option value="PDF">PDF</option>
          <option value="Audio">Audio</option>
          <option value="Video">Video</option>
          <option value="Image">Image</option>
          <option value="YouTube">YouTube</option>
        </TextField>

        {/* Conditional Media Input */}
        {formData.media_type === 'YouTube' ? (
          <TextField
            margin="dense"
            label="YouTube URL"
            name="media_url"
            value={formData.media_url}
            onChange={handleChange}
            fullWidth
          />
        ) : (
          <>
            <label htmlFor="media_url">Upload Media File</label>
            <input
              type="file"
              name="media_url"
              accept=".pdf,.mp3,.mp4,.jpg,.jpeg,.png"
              onChange={handleFileChange}
              style={{ marginTop: '15px' }}
            />
            {formData.media_url && (
              <Typography variant="body2">Media Preview: {formData.media_url}</Typography>
            )}
          </>
        )}

        {/* Thumbnail URL Upload */}
        <label htmlFor="thumbnail_url">Upload Thumbnail</label>
        <input
          type="file"
          name="thumbnail_url"
          accept=".jpg,.jpeg,.png"
          onChange={handleFileChange}
          style={{ marginTop: '15px' }}
        />
        {formData.thumbnail_url && (
          <CardMedia
            component="img"
            sx={{ width: 100, marginTop: '15px' }}
            image={formData.thumbnail_url}
            alt="Thumbnail Preview"
          />
        )}

        <TextField
          margin="dense"
          label="Description"
          name="des"
          value={formData.des}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{book ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

// Add/Edit Category Modal Component
const CategoryModal = ({ open, onClose, onSubmit, parentCategory, categoryToEdit }) => {
  const [categoryName, setCategoryName] = useState(categoryToEdit?.name || '');

  const handleSubmit = () => {
    onSubmit(categoryName);
    setCategoryName('');
  };

  useEffect(() => {
    setCategoryName(categoryToEdit?.name || '');
  }, [categoryToEdit]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {categoryToEdit ? 'Edit Category' : `Add Subcategory to "${parentCategory?.name || 'Root'}"`}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{categoryToEdit ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

// Confirmation Dialog Component
const ConfirmationDialog = ({ open, onClose, onConfirm, message }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">Cancel</Button>
      <Button onClick={onConfirm} color="primary">Confirm</Button>
    </DialogActions>
  </Dialog>
);

// Main Library Management Component
const LibraryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [currentCategoryPath, setCurrentCategoryPath] = useState([]); // Tracks the step-by-step path of selected categories
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [bookToEdit, setBookToEdit] = useState(null);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(() => {});
  const [isSubmitting, setIsSubmitting] = useState(false); // State variable for loading spinner

  const booksPerPage = 5;

  const currentCategoryId = currentCategoryPath[currentCategoryPath.length - 1]?.id || null;

  // Fetch categories
  useEffect(() => {
    fetch('https://mynewhomeschool.com/student/admin/api/library_management.php?action=fetch_categories')
      .then((res) => res.json())
      .then((data) => setCategories(data.categories));
  }, []);

  // Fetch books for the selected category
  useEffect(() => {
    if (currentCategoryId) {
      fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=fetch_books&category_id=${currentCategoryId}`)
        .then((res) => res.json())
        .then((data) => {
          setBooks(data.books);
          setFilteredBooks(data.books);
        });
    } else {
      setBooks([]);
      setFilteredBooks([]);
    }
  }, [currentCategoryId]);

  // Filter books based on search term
  useEffect(() => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    setFilteredBooks(
      books.filter((book) =>
        book.title.toLowerCase().includes(lowerCaseTerm)
      )
    );
  }, [searchTerm, books]);

  const getSubcategories = (parentId) => {
    return categories.filter(category => category.parent_id === parentId);
  };

  const handleCategoryClick = (category) => {
    setCurrentCategoryPath([...currentCategoryPath, category]);
  };

  const handleBackCategoryClick = (index) => {
    setCurrentCategoryPath(currentCategoryPath.slice(0, index + 1));  // Go back to a specific category in the path
  };

  const handleAddBook = () => {
    setBookToEdit(null);  // Reset to add mode
    setBookModalOpen(true);
  };

  const handleEditBook = (book) => {
    setBookToEdit(book);  // Set book to edit
    setBookModalOpen(true);
  };

  const confirmAction = (message, action) => {
    setConfirmationMessage(message);
    setOnConfirmAction(() => action);
    setConfirmationOpen(true);
  };

  const handleDeleteBook = (bookId) => {
    confirmAction('Are you sure you want to delete this content?', () => {
      fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=delete_book`, {
        method: 'POST',
        body: new URLSearchParams({ book_id: bookId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'success') {
            setBooks((prevBooks) => prevBooks.filter((book) => book.id !== bookId));
          }
        })
        .finally(() => {
          setConfirmationOpen(false);
        });
    });
  };

  const handleDeleteCategory = (categoryId) => {
    confirmAction('Are you sure you want to delete this category?', () => {
      fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=delete_category`, {
        method: 'POST',
        body: new URLSearchParams({ category_id: categoryId }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'success') {
            setCategories((prevCategories) => prevCategories.filter((cat) => cat.id !== categoryId));
          }
        })
        .finally(() => {
          setConfirmationOpen(false);
        });
    });
  };

  const handleBookSubmit = (formData) => {
    const action = bookToEdit ? 'edit_book' : 'add_book';
    const bookId = bookToEdit ? bookToEdit.id : null;

    const form = new FormData();
    form.append('title', formData.title);
    form.append('media_type', formData.media_type);
    form.append('des', formData.des);
    form.append('category_id', currentCategoryId);
    if (bookId) {
      form.append('book_id', bookId);
    }

    if (formData.media_url && formData.media_type !== 'YouTube') {
      const mediaFileInput = document.querySelector('input[name="media_url"]');
      if (mediaFileInput && mediaFileInput.files[0]) {
        form.append('media_url', mediaFileInput.files[0]);
      }
    } else if (formData.media_type === 'YouTube') {
      form.append('media_url', formData.media_url);
    }

    if (formData.thumbnail_url) {
      const thumbnailFileInput = document.querySelector('input[name="thumbnail_url"]');
      if (thumbnailFileInput && thumbnailFileInput.files[0]) {
        form.append('thumbnail_url', thumbnailFileInput.files[0]);
      }
    }

    setBookModalOpen(false); // Close the modal before submission
    setIsSubmitting(true); // Show the loading spinner

    fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=${action}`, {
      method: 'POST',
      body: form,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          // Update the books by refetching the data to ensure consistency
          if (currentCategoryId) {
            fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=fetch_books&category_id=${currentCategoryId}`)
              .then((res) => res.json())
              .then((data) => {
                setBooks(data.books);
                setFilteredBooks(data.books);
              });
          }
          setBookToEdit(null); // Reset the bookToEdit
        } else {
          // Optionally handle error response
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Optionally display error message to the user
      })
      .finally(() => {
        setIsSubmitting(false); // Hide the loading spinner
      });
  };

  const handleAddCategory = () => {
    setCategoryToEdit(null);
    setCategoryModalOpen(true);
  };

  const handleEditCategory = (category) => {
    setCategoryToEdit(category);
    setCategoryModalOpen(true);
  };

  const handleCategorySubmit = (categoryName) => {
    const parentCategoryId = categoryToEdit ? categoryToEdit.parent_id : currentCategoryId || '';
    const action = categoryToEdit ? 'edit_category' : 'add_category';
    const categoryId = categoryToEdit ? categoryToEdit.id : null;

    const params = new URLSearchParams();
    params.append('name', categoryName);
    if (parentCategoryId) {
      params.append('parent_id', parentCategoryId);
    }
    if (categoryId) {
      params.append('category_id', categoryId);
    }

    fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=${action}`, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          setCategoryModalOpen(false);
          setCategories((prevCategories) => {
            if (categoryToEdit) {
              return prevCategories.map((cat) =>
                cat.id === categoryId ? { ...cat, name: categoryName } : cat
              );
            } else {
              return [...prevCategories, data.category];
            }
          });
          setCategoryToEdit(null); // Reset the categoryToEdit
        }
      });
  };

  const resetToTopCategory = () => {
    setCurrentCategoryPath([]);
    setBooks([]);
    setFilteredBooks([]);
  };

  // Pagination
  const startIndex = (currentPage - 1) * booksPerPage;
  const paginatedBooks = filteredBooks.slice(startIndex, startIndex + booksPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Category Navigation */}
      <Box sx={{ width: '25%', p: 2, bgcolor: '#f4f4f4' }}>
        <Typography variant="h6" gutterBottom>Categories</Typography>

        {/* Reset Category Path */}
        <Button variant="outlined" color="primary" onClick={resetToTopCategory}>
          Back to Top Category
        </Button>

        {/* Current Category Path (Breadcrumb) */}
        <Box>
          {currentCategoryPath.map((category, index) => (
            <span key={category.id}>
              <Button color="primary" onClick={() => handleBackCategoryClick(index)}>
                {category.name}
              </Button>
              {index < currentCategoryPath.length - 1 && ' > '}
            </span>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Subcategories */}
        <List>
          {getSubcategories(currentCategoryId).map((subcategory) => (
            <ListItem
              button
              key={subcategory.id}
              onClick={() => handleCategoryClick(subcategory)}
              secondaryAction={
                <>
                  <IconButton onClick={() => handleEditCategory(subcategory)} color="primary">
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton onClick={() => handleDeleteCategory(subcategory.id)} color="secondary">
                    <DeleteIcon />
                  </IconButton> */}
                </>
              }
            >
              <ListItemText primary={subcategory.name} />
            </ListItem>
          ))}
        </List>

        {/* Add Category Button */}
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddCategory}>
          Add Subcategory
        </Button>
      </Box>

      {/* Books Display */}
      <Box sx={{ width: '75%', p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">Content</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Search by Title"
              fullWidth
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon />
              }}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddBook} disabled={!currentCategoryId}>
              Add Content
            </Button>
          </Grid>
        </Grid>

        {/* Book List */}
        <Box mt={2}>
          {paginatedBooks.length > 0 ? (
            paginatedBooks.map((book) => (
              <BookCard
                key={book.id}
                book={book}
                onEdit={handleEditBook}
                onDelete={handleDeleteBook}
              />
            ))
          ) : (
            <Typography>No books/content available</Typography>
          )}
        </Box>

        {/* Pagination */}
        {filteredBooks.length > booksPerPage && (
          <Pagination
            count={Math.ceil(filteredBooks.length / booksPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
          />
        )}
      </Box>

      {/* Add/Edit Book Modal */}
      <BookModal
        open={bookModalOpen}
        onClose={() => setBookModalOpen(false)}
        onSubmit={handleBookSubmit}
        book={bookToEdit}
      />

      {/* Add/Edit Category Modal */}
      <CategoryModal
        open={categoryModalOpen}
        onClose={() => setCategoryModalOpen(false)}
        onSubmit={handleCategorySubmit}
        parentCategory={currentCategoryPath[currentCategoryPath.length - 1] || null}
        categoryToEdit={categoryToEdit}
      />

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onConfirmAction}
        message={confirmationMessage}
      />

      {/* Loading Spinner */}
      <Backdrop open={isSubmitting} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default LibraryManagement;
